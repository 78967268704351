import React, { useState } from "react"
import gql from "graphql-tag"
import { Mutation } from "react-apollo"
import Popup from "reactjs-popup"

const CONTACT_FORM_MUTATION = gql`
  mutation CreateSubmissionMutation(
    $clientMutationId: String!
    $name: String!
    $email: String!
    $message: String!
    $phone: String!
  ) {
    createSubmission(
      input: { clientMutationId: $clientMutationId, name: $name, email: $email, message: $message, phone: $phone }
    ) {
      success
      data
    }
  }
`
const Contact = () => {
  //console.log("settings newsletter=", settings)
  const [nameValue, setNameValue] = useState("")
  const [emailValue, setEmailValue] = useState("")
  const [messageValue, setMessageValue] = useState("")
  const [phoneValue, setPhoneValue] = useState("")
  return (
    <Popup
      trigger={
        <button className="contact-btn">
          <b>Contactez nous !</b>
          <br />
          Devis gratuit
              </button>
      }
      modal
      closeOnDocumentClick
      contentStyle={{
        width: "auto",
        background: "transparent",
        border: "0",
      }}
    >
      {close => (
        <React.Fragment>
          <div className="modal-content">
            <Mutation mutation={CONTACT_FORM_MUTATION}>
              {(createSubmission, { loading, error, data }) => (
                <React.Fragment>
                  <form
                    className="newsletter"
                    onSubmit={async event => {
                      event.preventDefault()
                      createSubmission({
                        variables: {
                          clientMutationId: emailValue,
                          name: nameValue,
                          email: emailValue,
                          message: messageValue,
                          phone: phoneValue,
                        },
                      })
                    }}
                  >
                    <h2>
                      Remplir le formulaire
            </h2>
                    <div className="field">
                      <div className="control has-icons-left">
                        <input
                          className="input"
                          type="text"
                          placeholder="Entrez votre nom"
                          id="nameInput"
                          value={nameValue}
                          onChange={event => {
                            setNameValue(event.target.value)
                          }}
                        />
                        <span className="icon is-small is-left">
                          <i className="far fa-user"></i>
                        </span>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control has-icons-left">
                        <input
                          className="input"
                          type="phone"
                          placeholder="Entrez votre numéro de téléphone"
                          id="phoneInput"
                          value={phoneValue}
                          onChange={event => {
                            setPhoneValue(event.target.value)
                          }}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-phone-alt"></i>
                        </span>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control has-icons-left">
                        <input
                          className="input"
                          type="email"
                          placeholder="Entrez votre email"
                          id="emailInput"
                          value={emailValue}
                          onChange={event => {
                            setEmailValue(event.target.value)
                          }}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Entrez votre message"
                          id="messageInput"
                          value={messageValue}
                          onChange={event => {
                            setMessageValue(event.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control has-text-centered">
                        <button type="submit" className="button is-primary">
                          Envoyer
                        </button>
                      </div>
                    </div>
                    {loading && <p className="help">Chargement...</p>}
                    {error && (
                      <p className="help is-danger">
                        Une erreur est survenue, veuillez réessayer
                      </p>
                    )}
                    {data && (
                      <p className="help is-success">
                        Merci pour votre message nous reviendrons vers vous au plus vite !
                      </p>
                    )}
                  </form>
                </React.Fragment>
              )}
            </Mutation>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={close}
          ></button>
        </React.Fragment>
      )}
    </Popup>
  )
}

export default Contact
